import { selfTime, enlargeRP } from '@/plugins/plugins.js'
import { echo } from './echoModel.js'
import { handleStyle } from './handleStyle.js'
import { dataURLtoFile } from '@/plugins/compress.js'
import html2canvas from "html2canvas";
import $ from 'jquery'
import { mapState } from 'vuex'
import axios from 'axios'
// 处理颜色 hex转为cmyk
import { convertTo } from '@/plugins/hex_cmyk.js'
import COS from 'cos-js-sdk-v5'

import toConfigure from './toConfigure/toConfigure.vue'

let load = null, time = null

export default {
  components: {
    toConfigure
  },
  data() {
    return {
      // 当前类别数据
      curTypeData: {},
      id: null, 
      measurement: '1',
      options: [
        {value: '0.5', label: '50%'},
        {value: '0.75', label: '75%'},
        {value: '1', label: '100%'},
        {value: '1.5', label: '150%'},
        {value: '2', label: '200%'},
      ],
      model: {
        width: 0,
        height: 281
      },
      styleTitle: '',
      pageSize: 10,
      pageNum: 1,
      pageNums: 1,
      outputNum: 10,
      story: [],
      back: [],
      curModel: {
        bl: 3.78,
        width: 220, // 页宽度
        height: 307, // 页高度
        udSpacing: 0, // 卡片距离  上下间距
        aboutSpacing: 0, // 卡片距离  左右间距
        topSpacing: 5, // 页边距 上边距
        leftSpacing: 5, // 页边距 左边距
        bottomSpacing: 5, // 页边距 下边距
        rightSpacing: 5, // 页边距 右边距
        cardBorder: false,
        cardBorderColor: '#000',
        locatingHole: true,
        locatingMargin: 5,
        locatingDiameter: 3,
        dpi: 300,
        outputNum: 160
      },

      // 排版图片
      typeImg: [],
      varName: [],
      typeList: [],
      t: 'add',

      // 子组件开关
      typesetting_child: false,
      styleTitle_child: false,
      is_lookBack: true
    }
  },
  methods: {
    // 选择尺寸
    changeSelect(val) {
      $('.A4').css('transform', `scale(${val})`)

      this.updateOvflow()
    },
    // 双击
    doubleClick() {
      this.typesetting_child = true
    },
    // 查看背面
    lookBack( bool = true) {
      let model = this.curModel, bl = model.bl, back = [...this.back],
      start = (this.pageNum - 1) * this.pageSize, end = this.pageSize * this.pageNum
      // x轴可容纳数量
      let width = $('.guide').outerWidth(true) / bl
      
      let xN = Math.floor((model.width - model.leftSpacing - model.rightSpacing)/ width)
      console.log(start, end, xN, $('.guide').outerWidth(true))
      for(let i = start; xN > 1 && i < end; ) {
        var leng = Math.floor(xN / 2)
        for(let j = 0; j < leng; j++) {
          var temp = back[j + i]
          back[j + i] = back[i + xN - 1 - j]
          back[i + xN - 1 - j] = temp

          console.log(j + i, i + xN - 1 - j)
        }
        i += xN
      }
      // console.log(back.slice(start, end))
      this.is_lookBack = false
      $('.self').remove()
      // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
      $('.A4').append(...back.slice(start, end));
      $('.A4').css('justify-content', 'flex-end');

      bool && ([model.leftSpacing, model.rightSpacing] = [model.rightSpacing, model.leftSpacing])
      setTimeout(() => handleStyle(model), 100)
    },
    // 返回正面
    returnPositive () {
      this.is_lookBack = true

      $('.self').remove()
      // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
      $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
      $('.A4').css('justify-content', 'flex-start')

      let model = this.curModel;
      [model.leftSpacing, model.rightSpacing] = [model.rightSpacing, model.leftSpacing]
      setTimeout(() => handleStyle(model), 100)
    },
    // 上一页
    prvePage() {
      // this.is_lookBack = true
      this.pageNum --
      this.pageNums = this.pageNum

      if( !this.is_lookBack) this.lookBack(false) 
      else {
        $('.self').remove()
        // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
        $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
        $('.A4').css('justify-content', 'flex-start')
  
        setTimeout(() => {
          let photoBox = $('.typeImg'),
          guide = $('.guide'),
          curModel = this.curModel
  
          photoBox.css({
            'margin-right': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-left': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-top': curModel.udSpacing * curModel.bl + 'px',
            'margin-bottom': curModel.udSpacing * curModel.bl + 'px',
          })
  
          guide.css({
            'box-shadow': curModel.cardBorder ? `0 0 1px 1px ${curModel.cardBorderColor}` : 'none'
          })
        }, 10)
      }
    },
    // 下一页
    nextPage() {
      // this.is_lookBack = true
      this.pageNum ++
      this.pageNums = this.pageNum
      if( !this.is_lookBack) this.lookBack(false) 
      else {
        $('.self').remove()
        // this.typeImg = this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum)
        $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
        $('.A4').css('justify-content', 'flex-start')
  
        setTimeout(() => {
          let photoBox = $('.typeImg'),
          guide = $('.guide'),
          curModel = this.curModel
  
          photoBox.css({
            'margin-right': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-left': curModel.aboutSpacing * curModel.bl + 'px',
            'margin-top': curModel.udSpacing * curModel.bl + 'px',
            'margin-bottom': curModel.udSpacing * curModel.bl + 'px',
          })
  
          guide.css({
            'box-shadow': curModel.cardBorder ? `0 0 1px 1px ${curModel.cardBorderColor}` : 'none'
          })
        }, 10)
      }
    },
    searchBtn() {
      let maxPage = Math.ceil(this.story.length / this.pageSize)
      if(this.pageNums < 1) {
        this.pageNums = 1
      } else if(this.pageNums > maxPage) {
        this.pageNums = maxPage
      }

      if(this.pageNums != this.pageNum) {
        
        this.pageNum = this.pageNums
        if( !this.is_lookBack) this.lookBack(false) 
        else {
          $('.self').remove()
          $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
          $('.A4').css('justify-content', 'flex-start')
  
          setTimeout(() => {
            let photoBox = $('.typeImg'),
            guide = $('.guide'),
            curModel = this.curModel
  
            photoBox.css({
              'margin-right': curModel.aboutSpacing * curModel.bl + 'px',
              'margin-left': curModel.aboutSpacing * curModel.bl + 'px',
              'margin-top': curModel.udSpacing * curModel.bl + 'px',
              'margin-bottom': curModel.udSpacing * curModel.bl + 'px',
            })
  
            guide.css({
              'box-shadow': curModel.cardBorder ? `0 0 1px 1px ${curModel.cardBorderColor}` : 'none'
            })
          }, 10)
        }
      }
    },

    // 获取展示数量
    showNum(num, model) {
      this.typeImg = this.story.slice((this.pageNum - 1) * num, num * this.pageNum)
      console.log(num, this.pageNum, this.typeImg)
      setTimeout(() => handleStyle(model), 100)

      this.curModel = model
      // 保存缓存
      localStorage.setItem('style', JSON.stringify(model))
      this.pageSize = num

      console.log('展示数量: ' + num, '总页: '+ Math.ceil(this.story.length / num), '当前页: ' + this.pageNum)
      if(Math.ceil(this.story.length / num) < this.pageNum) {
        this.pageNum = 1
      }
      
      if( !this.is_lookBack) this.lookBack(false) 
      else {
        $('.A4 .self').remove()
        $('.A4').append(...this.story.slice((this.pageNum - 1) * num, num * this.pageNum))
      }
    },
    // 子组件关闭
    cancel() {
      this.typesetting_child = false
    },
    // worker
    test() {
      this.story = []
      $('.A4 .self').remove()
      let arr = [], varName = this.varName,
      {model} = this.curCardModel

      this.cardPerson.filter(its => {
        its.cardNum = its.cardNum == 0 ? 1 : its.cardNum
        console.log(its.cardNum)
        for(let i = 0; i < its.cardNum; i++) arr.push(its)
      })
      console.log(this.cardPerson)

      for(let j = 0; j < arr.length; j++) {
        var html = $('.typeModel').clone(true).addClass('self typeImg guide')
        let its = arr[j]
        for(let i of varName) {
          if(i == 'photoFileSaveName') {
            html.find('.'+i).css({
              'background-image': `url(${this.API.echoFace(its[i], this.modelId)})`,
              'background-repeat': 'no-repeat',
              'background-size': '100% 100%',
              'height': '100%',
            })
          } else {
            html.find('.'+i).text(its[i])
          }
        }

        this.story.push(html)
      }

      if(model.plate == "twoSided") {
        for(let j = 0; j < arr.length; j++) {
          var html = $('.back-typeModel').clone(true).addClass('self typeImg guide')
          let its = arr[j]
          for(let i of varName) {
            if(i == 'photoFileSaveName') {
              html.find('.'+i).css({
                'background-image': `url(${this.API.echoFace(its[i], this.modelId)})`,
                'background-repeat': 'no-repeat',
                'background-size': '100% 100%',
                'height': '100%',
              })
            } else {
              html.find('.'+i).text(its[i])
            }
          }
  
          this.back.push(html)
        }
      }

      console.log(`%c 总人数: ${this.story.length}`, 'color: skyblue; font-size: 30px;')

      this.pageSize = this.calcQuantity()
      $('.A4').append(...this.story.slice((this.pageNum - 1), this.pageSize))
      setTimeout(() => handleStyle(this.curModel), 100)
    },
    // 计算可渲染图片数量
    calcQuantity() {
      let model = this.curModel, bl = this.curModel.bl
      // x轴可容纳数量
      let width = $(this.story[0]).outerWidth() / bl + model.aboutSpacing * 2,
      height = $(this.story[0]).outerHeight() / bl + model.udSpacing * 2
      
      let xN = Math.floor((model.width - model.leftSpacing - model.rightSpacing) / width)
      // y轴可容纳数量
      let yN = Math.floor((model.height - model.topSpacing - model.bottomSpacing) / height)
      if(String(yN * xN) == 'NaN') return

      return yN * xN
    },

    // 临时图片
    getObjectURL(file) {
      var url = null ;
      if (window.createObjectURL!=undefined) { // basic
        url = window.createObjectURL(file) ;
      } else if (window.URL!=undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file) ;
      } else if (window.webkitURL!=undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file) ;
      }
      return url ;
    },
    // 选择排版
    chioceType(row) {
      this.curSelect = {...row}
      this.curModel = JSON.parse(row.pdfJson)
      // 保存缓存
      localStorage.setItem('style', row.pdfJson)
      this.id = row.id
      this.styleTitle = row.modelName

      let width = $('.typeImg').width()
      $('.self').remove()
      
      if(String(width) !== 'NaN') {
        this.pageSize = this.calcQuantity()
        console.log(this.pageSize)
        $('.A4').append(...this.story.slice((this.pageNum - 1) * this.pageSize, this.pageSize * this.pageNum))
      }

      this.updateOvflow()
    },
    updateOvflow() {
      setTimeout(() => {
        handleStyle(this.curModel)
        let A4 = $('.A4').outerWidth() * this.measurement * 1,
        whiteBoard = $('.white-board').outerWidth()
        console.log(A4, whiteBoard)
        if(A4 > whiteBoard) {
          $('.A4').css({'left': '0','transform-origin': 'left top'})
          $('.white-board').css({'overflow-x': 'auto',})
        } else {
          $('.A4').css({'left': 'auto','transform-origin': 'center top'})
          $('.white-board').css({'overflow-x': 'hidden',})
        }
      }, 100)
    },
    delectRow(its) {
      this.$confirm('是否删除该排版?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(this.API.typedel(its.id))
          .then((res) => {
            // let res = JSON.parse(body)
            if(res.code == '000') {
              this.$message.success('删除成功!')
              this.getTypeset()
            } else {
              this.$message.error(res.msg)
            }
          })
      })
    },

    transition(t) {
      this.t = t
      if(t == 'add') {
        this.styleTitle = ''
      }
      this.styleTitle_child = true
    },

    // 保存排版样式
    save() {
      if(!this.styleTitle){ 
        this.styleTitle_child = true
        return 
      } else this.styleTitle_child = false
      
      let model = {...this.curModel}
      if(!this.is_lookBack) ([model.leftSpacing, model.rightSpacing] = [model.rightSpacing, model.leftSpacing])
      let obj = {
        modelName: this.styleTitle,
        pdfJson: JSON.stringify(model)
      }, 
      url = 'typeadd'
      
      if(this.t == 'edit') {
        obj.CreateTime = this.curSelect.CreateTime
        obj.companyId = this.curSelect.companyId
        obj.id = this.id
        url = 'typeSave'
      }

      this.$http.post(this.API.typeadd(url), obj)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000') {
            this.$message.success('添加成功!')
            this.typesetting_child = false
            this.getTypeset()
          } else {
            this.$message.error(res.msg)
          }
        })
    },

    generateCard() {
      this.$confirm('是否导出PDF文件?', '提示', {  
        closeOnPressEscape: false,
        closeOnClickModal: false,
        cancelButtonClass: 'btn_custom_cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        $('.el-message-box__btns .child').remove()

        var { collection, model } = JSON.parse(JSON.stringify(this.curCardModel)),
        filter = ['zIndex', 'fontSize', 'opacity', ''],
        background = {...model, modelName: this.fileTitle},
        elements = JSON.parse(JSON.stringify(collection)),
        personIds = this.cardPerson.map(i => i.id),
        card = [], pdf = {personIds: [], downPdf: this.paiban()}, termination = [], that = this

        background.color = convertTo('hex', 'rgb', background.color)
        background.backColor = convertTo('hex', 'rgb', background.backColor)
        background.id = this.modelId
        delete background.Cover
    
        background.width = (11.81 * background.width).toFixed(0) * 1
        background.height = (11.81 * background.height).toFixed(0) * 1
    
        for(let i = 0; i < elements.length; i++) {
          if(elements[i].eleType == 'text') {
            elements[i].color = convertTo('hex', 'rgb', elements[i].font.color)
            elements[i].fontSize = elements[i].font.size
            elements[i].center = elements[i].font.textAlign
            elements[i].fonts = elements[i].font.fonts
          } else {
            elements[i].radio = elements[i].border.radius
          }
          delete elements[i].id
          delete elements[i].font
          delete elements[i].border
          delete elements[i].boxshadow
    
          for(let j in elements[i]) {
            if(typeof elements[i][j] == 'number' && !filter.includes(j))
              elements[i][j] = (11.81 * elements[i][j]).toFixed(0) * 1
          }
        }
        
        load = this.$loading({
          lock: true,
          text: '正在输出, 请等待...',
          customClass: 'loding-size',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        })

        // 分批下载pdf termination 
        let leng = Math.ceil(personIds.length / this.curModel.outputNum)

        lx()
        function lx(i = 0) {
          if(leng == i) {
          } else {
            pdf.downPdf.pdfPage = i + 1
            pdf.personIds = personIds.slice(i * that.curModel.outputNum, (i + 1) * that.curModel.outputNum)
            var str = JSON.stringify({card: [{background, dpi: that.curModel.dpi, elements}], pdf}, false, 2),
            file = new File([str], 'phone.txt', { type:  "text/plain;charset=utf-8"  })
    
            that.uploadDoc(file, leng, termination)
            setTimeout(() => {
              lx(i + 1)
            }, 3000)
          }
        }
        return 

        // load = this.$loading({
        //   lock: true,
        //   text: '正在输出, 请等待...',
        //   customClass: 'loding-size',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.5)'
        // })
    
        // // 没有结果
        // axios.post(this.API.element, obja,{
        //   headers: {
        //     token: localStorage.getItem('tokenCard')
        //   }
        // }).then(res => {})
        //   .catch(err => setTimeout(() => this.paiban(), 2000))

      }).catch(err => $('.el-message-box__btns .child').remove())

      setTimeout(() => {
        // console.log(this.curCardModel, this.cardTypeList)
        // let [curCardObj] = this.cardTypeList.filter(its => this.curCardModel.cardTag == its.id && its)
        let child = $(`<span>类型：${this.curTypeData.tagName}</span>`).addClass('child').css({
          "float": 'left',
          'margin-top': '6px',
          'font-size': '16px'
        })
        
        $('.el-message-box__btns').append(child)
      }, 100)
    },
    // 获取上传文档签名
    getDocSignature(options, callback) {
      const that = this
      $.ajax({
        url: this.API.uploadSizeMax, // 获取客户端上传签名的 URL
        type: 'post',
        headers: {
          token: localStorage.getItem('tokenCard')
        },
        dataType: 'json',
        success(result) { // result 是派发签名服务器的回包
          // let result = JSON.parse(body)
          if (result.code === '000') {
            const fileKey = result.data
            console.log(fileKey)
            callback({
              TmpSecretId: fileKey.TmpSecretId,
              TmpSecretKey: fileKey.TmpSecretKey,
              XCosSecurityToken: fileKey.Token,
              ExpiredTime: result.time,
            })
          } else {
            // 获取秘钥失败的相关操作
          }
        }
      })
    },
    uploadDoc(fileObj, leng, termination) { // 调取文档上传相关接口
      console.log(fileObj.name.substr(fileObj.name.length - 4))
      let suffix = '.txt',
      companyId = JSON.parse(localStorage.getItem('loginInfo')).companyId,
      dir = 'cardFile/' + companyId + '/' + new Date().getTime() + suffix
      
      this.cos.putObject({
        Bucket: 'hzcard-1305256445',  // 桶的名字，命名规则为{name}-{appid} ，此处填写的存储桶名称必须为此格式
        Region: 'ap-guangzhou',   //Bucket所在的区域
        Key: dir, // 文件地址: 公司id + 随机id
        StorageClass: 'STANDARD',
        Body: fileObj, // 文件的信息
      }, (err, data) => {
        console.log(err)
        if (!err && data) {
          console.log(data)
          // 没有结果
          axios.post(this.API.makeCardToPdf(dir), {},{
            headers: {
              token: localStorage.getItem('tokenCard')
            }
          }).then(({data}) => {
            let res = data
            console.log(res)
            if(res.code == '000') {
              let arr = res.data
              for(let i = 0; i < arr.length; i++) {
                var a = document.createElement('a')
                a.setAttribute('target', '_blank')
                a.setAttribute('download', `test-${i+1}.pdf`)
                a.href = `https://hzcard-1305256445.cos.ap-guangzhou.myqcloud.com/${arr[i]}`
                a.click()
              }
            } else {
              this.$message.error(res.msg)
            }
            
            termination.push(true)
            if(termination.length == leng) {
              load.close()
              load = null
            }
          })
        } else {
          this.$message.error('上传失败')
        }
      })
    },

    // 导出pdf
    paiban() {
      let downPdf = {...this.curModel}
      if(!this.is_lookBack) ([downPdf.leftSpacing, downPdf.rightSpacing] = [downPdf.rightSpacing, downPdf.leftSpacing])

      downPdf.frame = downPdf.cardBorder ? 1 : 0
      downPdf.locatingHole = downPdf.locatingHole ? 1 : 0
      downPdf.locatingHolex = downPdf.locatingHoley = downPdf.locatingMargin
      downPdf.locatingHoler = downPdf.locatingDiameter
      downPdf.personNum = downPdf.outputNum * 1
      downPdf.cardName = this.fileTitle
      downPdf.pdfType = 'out'

      downPdf.imageWidh = Math.ceil($('#model').width() / downPdf.bl)
      downPdf.imageHeight = Math.ceil($('#model').height() / downPdf.bl)

      delete downPdf.cardBorder
      delete downPdf.cardBorderColor
      delete downPdf.locatingMargin
      delete downPdf.locatingDiameter
      delete downPdf.bl
      delete downPdf.outputNum
      
      return downPdf
    },
    getTypeset() {
      this.$http.post(this.API.typeselect)
        .then((res) => {
          // let res = JSON.parse(body)
          if(res.code == '000') {
            this.typeList = res.data
          }
        })
    }
  },
  computed: {
    ...mapState(['cardPerson', 'modelId', 'curCardModel', 'fileTitle', 'curCardType', 'cardTypeList'])
  },
  created() {
    this.getTypeset()
    let style = JSON.parse(localStorage.getItem('style')) || null

    style ? this.curModel = style : null
    // this.pageSize = localStorage.getItem('pageNum') || 10
    this.updateOvflow()

    // 初始化上传文档实例
    this.cos = new COS({ 
      getAuthorization: this.getDocSignature
    })
  },
  mounted() {
    var data = this.curCardModel,
    collection = data.collection,
    model = data.model
    setTimeout(() => {
      this.varName = echo({collection, model, vue: this})
    
      this.model = {
        width: $('#model').width(),
        height: $('#model').height(),
      }
    }, 100)

    setTimeout(() => {
      this.test()
    }, 500)

    // 过滤出当前类别数据
    this.curTypeData = this.cardTypeList.filter(its => its.id == this.curCardType && its)[0]
  }
}