let _ = null

export function compress(fileObj, back, idx = 1) {
  // 文件读取对象
  var reader = new FileReader();
  // 获取文件名称
  var imgType = fileObj.name.lastIndexOf('.')
  var fileName = fileObj.name.substr(0, imgType)
  // 图片转base64（Blob对象或File对象）
  reader.readAsDataURL(fileObj);
  // 图片大小<=100kb，size的单位：B（1Mb=1B*1024*1024）
  if(fileObj.size/1024 <= 100) {
    back(fileObj)
  } else {
    // 读取完毕
    reader.onload = function(){
      // 图片转换成为base64Url
      var fileBase64 = this.result;
      // 图片参数
      var imgAttr = {quality : .5, type : 'img/png'};
      
      // 压缩图片
      photoCompress(fileBase64, imgAttr, idx, res => {
        compress(dataURLtoFile(res, fileName), back, idx+1)
      });
    };
  }
}

/**
 * Base64 转为图片格式
 * @param {*} dataurl 
 * @param {*} filename 
 * @returns 
 */
export function dataURLtoFile(dataurl, filename = 'file') {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
      type: mime
  })
}
function photoCompress(base64Url, imgAttr, idx, callback) {
  var img = new Image();
  img.src = base64Url;
  img.onload = function(){
    var that = this;
    // 默认按比例压缩
    var w = that.width * (1 - (idx / 10)),
      h = that.height * (1 - (idx / 10))

    //生成canvas
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    // 创建属性节点
    var anw = document.createAttribute("width");
    anw.nodeValue = w;
    canvas.setAttributeNode(anw);
    var anh = document.createAttribute("height");
    anh.nodeValue = h;
    canvas.setAttributeNode(anh);

    ctx.drawImage(that, 0, 0, w, h);
    // 重绘后的图片类型
    var imgType = imgAttr.type || 'image/jpeg';
    var base64 = canvas.toDataURL(imgType);
    callback(base64)
  }
}