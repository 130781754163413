import $ from 'jquery'

/**
  nl: 5,
  width: 210,
  height: 297,
  udSpacing: 0,
  aboutSpacing: 0,
  topSpacing: 0,
  leftSpacing: 0,
  bottomSpacing: 0,
  rightSpacing: 0,
  cardBorder: false,
  cardBorderColor: '#000',
  locatingHole: false,
  locatingMargin: 5,
  locatingDiameter: 3
 * @param {*} model 
 */
export const handleStyle = function(model) {
  let paper = $('.A4'), photoBox = $('.typeImg'), 
    guide = $('.guide'), circular = $('.long')

  // localStorage.setItem('style', JSON.stringify(model))

  for(let label in model){
    let val = model[label],
    px = null
    
    switch(label){
      case 'width': paper.css('width', val * model.bl + 'px'); break
      case 'height': paper.css('height', val * model.bl + 'px'); break
      case 'udSpacing': 
        photoBox.css({
          'margin-top': val * model.bl + 'px',
          'margin-bottom': val * model.bl + 'px',
        }); break
      case 'aboutSpacing': 
        photoBox.css({
          'margin-right': val * model.bl + 'px',
          'margin-left': val * model.bl + 'px',
        }); break
      case 'topSpacing': paper.css('padding-top', val * model.bl + 'px'); break
      case 'leftSpacing': paper.css('padding-left', val * model.bl + 'px'); break
      case 'bottomSpacing': paper.css('padding-bottom', val * model.bl + 'px'); break
      case 'rightSpacing': paper.css('padding-right', val * model.bl + 'px'); break
      case 'cardBorder': 
        if(val)
          guide.css('box-shadow', `0 0 1px 1px ${model.cardBorderColor}`)
        else 
          guide.css('box-shadow', `none`)

        ; break
      case 'cardBorderColor': 
        if(model.cardBorder)
          guide.css('box-shadow', `0 0 1px 1px ${val}`)
        else 
          guide.css('box-shadow', `none`)
        ; break
      case 'locatingHole': 
        if(val)
          circular.css('display', 'flex')
        else 
          circular.hide()
        ; break
      case 'locatingMargin': 
        px = (val - 5) * model.bl + 'px'
        $('.long-lt').css({'left': px, 'top': px})
        $('.long-rt').css({'right': px, 'top': px})
        $('.long-lb').css({'left': px, 'bottom': px})
        $('.long-rb').css({'right': px, 'bottom': px})
        ; break
      case 'locatingDiameter': 
        px = val * model.bl + 'px'
        $('.circular').css({'width': px, 'height': px})
        ; break
    }
  }
}