import $ from 'jquery'
let copy, bl = 3.78
let url = `https://backgroupcard-1305256445.cos.ap-guangzhou.myqcloud.com/`

export function echo({collection, model, vue}) {
  window.vue = vue
  // 更换类名
  $('.info').removeClass('info')

  // 复制节点 防止被清空
  copy = $('.templateOS').clone(true)

  // 收集变量名
  let varName = []
  console.log(collection)
  for(let i = 0; i < collection.length; i++) {
    var curObj = collection[i], side = curObj.side, dom
    curObj.variable && varName.push(curObj.variable)
    
    if(side == 'twoSided') dom = '.back-typeModel'
    else dom = '.typeModel'

    var node = $(copy).clone(true).attr({
      'id': curObj.id
    }).css({
      'display': 'block',
      'transform': 'translate(' + curObj.x * bl + 'px,' + curObj.y * bl + 'px)'
    }).addClass('record')

    // 样式回显
    styleEcho(node, curObj)

    $(dom).append(node)
  }
  
  // model 样式回显
  $('.typeModel').css({
    'width': twoValue(model.width * bl)+'px',
    'height': twoValue(model.height * bl)+'px',
    'background-color': model.color,
    'background-image': `url(${url + model.image})`,
    'position': 'relative'
  })
  if(model.plate == 'twoSided'){
    $('.back-typeModel').css({
      'width': twoValue(model.width * bl)+'px',
      'height': twoValue(model.height * bl)+'px',
      'background-color': model.backColor,
      'background-image': `url(${url + model.backImage})`,
      'position': 'relative'
    })
  }

  return varName
}

function styleEcho(tNode, curObj) {
  let { boxshadow, border } = curObj
  if(curObj.eleType == 'image'){
    $(tNode).css({
      'background-image': `url(${url + curObj.image})`,
      'background-size': `100% 100%`,
    });
  } else if(curObj.eleType == 'text') {
    // font 相关
    let { font, conten } = curObj
    $(tNode).find('.cte').html(conten);
    $(tNode).find('.cte').css({
      'font-family': font.family,
      'font-size': font.size+'px',
      'letter-spacing': font.spacing+'px',
      'line-height': font.lineHeight * bl+'px',
      'color': font.color,
      'text-align': font.textAlign,
      'text-align-last': font.textAlign == 'justify' ? 'justify' : 'inherit',
      'font-weight': font.fontWeight,
      'text-decoration': font.textDecoration,
      'font-style': font.italics,
      'overflow': 'hidden'
    })
  }

  $(tNode).css({
    'width': curObj.width * bl + 'px',
    'height': curObj.height * bl + 'px',
    'opacity': curObj.opacity / 100,
    'border': `${border.width}px ${border.style} ${border.color}` ,
    'border-radius': border.radius + 'px',
    'box-shadow': `${boxshadow.x}px ${boxshadow.y}px ${boxshadow.vague}px ${boxshadow.size}px ${boxshadow.color}`,
    'position': 'absolute'
  });

  if(curObj.variable) {
    // $(tNode).css('border', `1px solid red`);
    $(tNode).find('.cte').html('');
    $(tNode).find('.cte').addClass(curObj.variable)
  }
}

function twoValue(data) {
  data = String(data)
  let [z, x] = data.split('.')
  if(x) {
    let twoVal = x.substr(0, 2)
    return `${z}.${twoVal}`
  } else {
    return data
  }
}