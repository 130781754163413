<template>
  <div class="typesetting">
    <div class="func">
      <div class="">
        <el-button size="small" @click="prvePage" :disabled='pageNum == 1 ? true : false'> 上一页 </el-button>
        <el-button size="small" @click="nextPage" :disabled='(pageSize * pageNum) >= story.length ? true : false'> 下一页 </el-button>
        <el-input size="small" style="width: 60px; margin: 0 10px;" v-model="pageNums" @input="pageNums = pageNums.replace(/^\.+|[^\d.]/g,'')" @keyup.native.enter="searchBtn" @blur="searchBtn"/>
        <el-button size="small">页数: {{ pageNum + '/' + Math.ceil(story.length / pageSize) }}</el-button>
        <el-button size="small" @click="generateCard">生成PDF</el-button>
        <el-button size="small" @click="lookBack" v-if="curCardModel.model.plate == 'twoSided' && is_lookBack">查看背面</el-button>
        <el-button size="small" @click="returnPositive" v-else-if="curCardModel.model.plate == 'twoSided'">返回正面</el-button>
        <el-select v-model="measurement" placeholder="请选择" size="small" @change="changeSelect" style="width: 120px; margin: 0 50px">
          <el-option v-for="item in options" :key="item.value"
            :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 模子 -->
    <div class="mode">
      <div class="typeModel" id="model">
        <div class="templateOS">
          <div class="cte"></div>
          <div class="rightBottom"></div>
        </div>
      </div>

      <div class="back-typeModel" id="model"></div>
    </div>

    <div class="content" :style="{'top': -( model.height + 5 )+'px'}">
      <!-- <div class="model-position"></div> -->
      <div class="white-board" @dblclick="doubleClick">
        <!-- A4纸张 -->
        <div class="A4" @dblclick="doubleClick" :style="{'width': curModel.width * curModel.bl + 'px', 'height': curModel.height * curModel.bl + 'px'}">
          <!-- 定位孔 -->
          <div class="long-lt long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>
          <div class="long-rt long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>
          <div class="long-lb long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>
          <div class="long-rb long">
            <div class="before"></div>
            <div class="after"></div>
            <div class="circular"></div>
          </div>

          <!-- 页标题 -->
          <div class="page-title">{{ fileTitle }} - 第{{ pageNum }}页{{curCardModel.model.plate == 'twoSided' ? (is_lookBack ? '(正)' : '(背)') : ''}} ({{ curTypeData.tagName }})</div>

          <!-- 卡片 -->
          <!-- <div id="typeImg" class="typeImg" v-for="(its, idx) in typeImg" :key="idx"
           :style='{width: model.width+"px", height: model.height+"px" }'>
            <img :src="its" class="guide">
          </div> -->

        </div>
      </div>
      <div class="person">
        <div class="single" v-for="(its, idx) in typeList" :key="idx" @click.self="chioceType(its)">
          {{ its.modelName }}
          <i class="el-icon-delete" title="删除" @click="delectRow(its)"></i>
        </div>
      </div>
    </div>

    <!-- 排版配置 -->
    <el-dialog title="排版配置" :visible.sync="typesetting_child" width="500px" center
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <toConfigure ref="toConfigure" v-if="typesetting_child" @showNum='showNum' @save='transition' :id='id' :model='curModel'></toConfigure>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="cancel" size="small" round>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 排版名称 -->
    <el-dialog title="排版名称" :visible.sync="styleTitle_child" width="20%" center class="model"
      :close-on-click-modal='false' :close-on-press-escape='false' custom-class='dialog' top='80px'>
      <el-input v-model="styleTitle" placeholder="请输入排版名称"></el-input>
      <div class="dialog-btn">
        <el-button v-preventReClick type="primary" @click="save" size="small" round>确 定</el-button>
        <el-button v-preventReClick @click="styleTitle_child = false" size="small" type="danger" round>取 消</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import typesetting from './typesetting.js'

export default typesetting
</script>

<style lang="less" scoped>
@import url('./typesetting.less');
</style>

<style lang="less">
.typesetting {
  .el-dialog {
    margin: 80px  auto!important;
  }

  .back-typeModel, .typeModel {
    width: 500px;
    height: 281px;
    // background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
  }
  
}

.loding-size {
  font-size: 28px;
}
</style>